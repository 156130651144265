<template>
  <ul class="sidebar-list-nested" :class="`sidebar-list-nested--${appLanguage}`">
    <template v-if="item.nestedListItems.length && item.id !== 'language'">
      <template v-for="(nestedItem, index) in item.nestedListItems">
        <li
          v-if="isAllowedToDisplay(nestedItem.routeName) && !nestedItem.alwaysHidden"
          :key="`${item.id}-${index}`"
          class="sidebar-list-nested__list-item"
        >
          <router-link
            class="sidebar-list-nested__link"
            :to="{name: nestedItem.routeName}"
            :data-name="item.id"
            :data-test="nestedItem.dataTest ? nestedItem.dataTest : item.id"
          >
            {{ $t(nestedItem.displayName) }}
          </router-link>
        </li>
      </template>
    </template>
    <template v-if="item.id === 'listOfArticles'">
      <li class="sidebar-list-nested__list-item" @click="setSite(0); closeSidebar();">
        <router-link
          class="sidebar-list-nested__link all-articles"
          :class="{'sidebar-list-nested__link--active': isActive(0)}"
          :to="{ name: 'article_list' }"
          :data-name="item.id"
        >
          {{ $t('menu.all_articles') }}
        </router-link>
      </li>
      <li
        class="sidebar-list-nested__list-item"
        v-for="(site, index) in sites"
        :key="`item-${index}`"
        @click="setSite(site.id); closeSidebar();"
      >
        <span
          :class="[
            'sidebar-list-nested__link',
            (site.active && $route.name === 'article_list' ? 'sidebar-list-nested__link--active' : '')
          ]"
        >
          {{ site.title }}
        </span>
      </li>
    </template>
    <template v-if="item.id === 'language'">
      <li
        class="sidebar-list-nested__list-item"
        :class="`sidebar-list-nested__list-item--${nestedItem.displayName.toLowerCase()}`"
        v-for="nestedItem in item.nestedListItems"
        :key="`language-${nestedItem.displayName}`"
        @click="setLanguage(nestedItem.displayName); closeSidebar();"
      >
        {{ nestedItem.displayName }}
      </li>
    </template>
  </ul>
</template>

<script>
import ArticleFilterModel from '@/model/ArticleFilter'
import { MODULE_ARTICLE } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'DashSidebarUlNested',
  props: {
    isAllowedToDisplay: {
      type: Function,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      appLanguage: localStorage.getItem('lang'),
      articleSiteFilter: null
    }
  },
  computed: {
    sites () {
      const menuSites = []
      const sites = this.$store.getters['site/enabledSites'](MODULE_ARTICLE)
      sites.forEach(site => {
        let active = false
        if (this.articleSiteFilter === site.id) {
          active = true
        }
        const menuSite = {
          id: site.id,
          title: site.title,
          active: active
        }
        menuSites.push(menuSite)
      })
      return menuSites
    },
    lastFiltered () {
      return this.$store.getters['article/lastFiltered']
    }
  },
  methods: {
    closeSidebar () {
      this.$store.commit('dash/setSidebarOpen', false)
    },
    isActive (id) {
      return (this.articleSiteFilter === id || this.articleSiteFilter === null || isNaN(this.articleSiteFilter)) &&
        this.$route.name === 'article_list'
    },
    setLanguage (selectedlanguage) {
      let language
      if (selectedlanguage === 'Czech') {
        language = 'cz'
      }
      if (selectedlanguage === 'English') {
        language = 'en'
      }
      if (selectedlanguage === 'Slovak') {
        language = 'sk'
      }
      this.appLanguage = language
      this.$i18n.locale = language
      window.localStorage.setItem('lang', language)
      // Set HTML lang
      document.documentElement.lang = language
    },
    setSite (id) {
      const filter = this._.cloneDeep(ArticleFilterModel)
      filter.site = id
      this.$store.commit('article/setPage', 1)
      this.$store.commit('article/setFilter', filter)
      this.$store.dispatch('article/fetch')
      this.$router.push('/article')
    }
  },
  watch: {
    lastFiltered () {
      const articleFilter = this.$store.getters['article/filter']
      this.articleSiteFilter = parseInt(articleFilter.site)
    }
  }
}
</script>

<style scoped lang="scss">
  .sidebar-list-nested {
    @include padding(_ _ _ 20px);
    display: none;
    .sidebar__list-item--submenu & {
      display: block;
    }
    .dash--nav-not-hover & {
      @include bp(7) {
        display: none;
      }
    }
    &__list-item {
      @include font(500 15px "Roboto");
      list-style: none;
      cursor: pointer;
      color: #465674;
      .sidebar-list-nested & {
        @include margin(15px _ _);
        @include font(500 14px "Roboto");
        &:last-child {
          @include margin(_ _ 15px);
        }
      }
      &--czech {
        .sidebar-list-nested--cz & {
          color: #6599fe;
        }
      }
      &--english {
        .sidebar-list-nested--en & {
          color: #6599fe;
        }
      }
      &--slovak {
        .sidebar-list-nested--sk & {
          color: #6599fe;
        }
      }
    }
    &__link {
      @include font(500 14px "Roboto");
      color: #465674;
      display: block;
      .sidebar-list-nested & {
        color: #838383;
        &--active,
        &.active {
          color: #6599fe;
        }
        &:not(.router-link-active):not(.sidebar-list-nested__link--active) {
          &:hover {
            color: #404856;
          }
        }
        &.all-articles:not(.sidebar-list-nested__link--active) {
          color: #838383;
          &:hover {
            color: #404856;
          }
        }
      }
    }
  }
</style>
